@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

























































.discipline-selection-input__content {
  max-height: 600px;
  height: 100%;
  width: 100%;
}

.disciplines-selection__label {
  color: rgba($primary, 0.5);
}

.discipline-selection-input .v-input__control {
  height: 100%;
  display: flex;
  flex-direction: column;

  .v-input__slot {
    flex-grow: 1;
  }

  .v-messages {
    flex-grow: 0;
    margin-bottom: 0.5rem;
  }
}

.discipline-selection-input
  .v-list-group--active
  > .v-list-group__header
  > .v-list-group__header__prepend-icon
  .v-icon {
  transform: rotate(90deg);
}
.discipline-selection-input .v-list-group--disabled * {
  cursor: not-allowed;
}
