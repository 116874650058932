@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

























































































.assignment-comment__input__btn {
  &.theme--light.v-btn.v-btn--disabled {
   * {
     color: $itaa-blue !important;
     opacity: 0.75;
   }
  }
}

.assignment-comment__input__validator {
  &--max-height {

  }
}

.assignment-comment__input__text-area {
  > * textarea {
    max-height: 10rem;
    overflow-y: auto;
  }

}
