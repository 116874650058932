@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';























































.assignment-overview__assignment-filter {
  min-height: 18rem;
  max-height: 18rem;
  height: 18rem;
}

.assignment-overview__assignment-list {
  height: 100vh;
}
