@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































.discipline-selection-input__container {
  background-color: transparent !important;
}
