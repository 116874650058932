@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




































































































.assignment-form__container {
  min-height: 30.85rem;
}

.assignment-form__comment {
  > * .v-input__slot {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  > * .v-text-field__details {
    flex-grow: 0;
  }
  > * textarea {
    flex-grow: 1!important;
  }
}
