@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















































































.create-assignment__fields__discipline-selection-input {
  height: 100%;
}
