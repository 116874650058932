@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
































































































































.assignment-selection-table {
  .v-data-table__wrapper {
    overflow-x: hidden;
  }
}

.assignment-selection-table__row {
  cursor: pointer;

  &--selected {
    background-color: $accent;
    &:not(:hover) {
      color: $white;

      .v-icon {
        color: $white;
      }
    }
  }
}
