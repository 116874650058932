@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';










































































.comment-bubble__divider {
  background-color: white;
  height: 1px;
}
.comment-bubble {
  background-color: $itaa-blue !important;

  &--primary {
    position: relative;
    margin-left: 2rem;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 1rem;
      width: 0;
      height: 0;
      border: 1rem solid transparent;
      border-left-color: $itaa-blue !important;
      border-right: 0;
      margin-top: -0.5rem;
      margin-right: -0.5rem;
    }
  }

  &--secondary {
    position: relative;
    opacity: 0.5;
    margin-right: 2rem;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 1.5rem;
      width: 0;
      height: 0;
      border: 1rem solid transparent;
      border-right-color: $itaa-blue !important;
      border-left: 0;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
    }
  }
}


.comment-bubble-edit {
  width: fit-content;
  font-size: size(13);
}
